<template>
  <div class="container">
    <b-row>
      <b-col class="text-left p-0">
        <h2 class="title-classe">Classe</h2>
      </b-col>
      <b-col class="text-right pr-4">
        <b-button @click="addPage()" class="btnIncluir" variant="danger">
          Incluir
        </b-button>
      </b-col>
    </b-row>

    <hr />

    <b-row>
      <b-col class="pl-0 pr-4">
        <div class="content-input">
          <h5 class="text-left title-search-classe">
            Pesquisa de Classe
          </h5>
          <div class="w-100">
            <div class="content-search items-center pl-2">
              <lupaPNG />
              <img
                class="content-iconSearch-classe"
                src="@/assets/img/lupa.png"
                alt="Lupa"
              />
              <input class="w-100 form-inputStyleF m-0 pl-3 py-1" type="text" />
            </div>
          </div>
        </div>
      </b-col>
    </b-row>

    <b-row>
      <b-col class="pl-0">
        <div class="mt-4">
          <div class="content-table">
            <table class="tableStyle w-100">
              <tr>
                <th class="titleThF w-25">Classe</th>
                <th class="titleThF w-50">Descrição</th>
                <th class="MtitleThF ">Visualizar</th>
                <th class="MtitleThF ">Editar</th>
                <th class="MtitleThF ">Excluir</th>
              </tr>

              <tr class="rowTable">
                <td class="contentTdF">1</td>
                <td class="contentTdF">Teste</td>
                <td class="MtitleThF ">
                  <a @click="viewPage()">
                    <showSVG />
                    <img
                      class="icon-trash"
                      src="@static/imgs/components/table/eye.svg"
                    />
                  </a>
                </td>

                <td>
                  <a @click="editPage()">
                    <editarSVG />
                    <img
                      class="icon-edit"
                      src="@static/imgs/components/table/editar.svg"
                    />
                  </a>
                </td>

                <td >
                  <a  @click="$bvModal.show('bv-modal-example')">
                    <excluirSVG />
                    <img
                      class="icon-trash"
                      src="@static/imgs/components/table/excluir.svg"
                    />
                  </a>
                </td>
              </tr>

              <br />

              <tr class="rowTable">
                <td class="contentTdF">2</td>
                <td class="contentTdF">Teste 2</td>
                <td class="MtitleThF ">
                  <a @click="viewPage()">
                    <showSVG />
                    <img
                       class="icon-trash"
                      src="@static/imgs/components/table/eye.svg"
                    />
                  </a>
                </td>

                <td>
                  <a @click="editPage()">
                    <editarSVG />
                    <img
                       class="icon-edit"
                      src="@static/imgs/components/table/editar.svg"
                    />
                  </a>
                </td>

                <td
                  id="show-btn"
                  @click="$bvModal.show('bv-modal-example')"
                >
                  <excluirSVG />
                  <img
                     class="icon-trash"
                    src="@static/imgs/components/table/excluir.svg"
                  />
                </td>

                <b-modal id="bv-modal-example" hide-footer>
                  <div class="d-block text-center content-modelGeneral">
                    <h3 class="model-content1">
                      Tem certeza de que deseja exluir esse
                      <span class="del-alert"> Cadastro?</span>
                    </h3>
                    <div class="text-right"> 
                    <b-button
                      class="btn-del"
                      variant="info"
                    >
                      Sim
                    </b-button>

                        <b-button
                      class="btn-del"
                      variant="danger"
                    >
                     Não
                    </b-button>
                    </div>
                  </div>
                </b-modal>

              </tr>

            </table>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import excluirSVG from "@static/imgs/components/table/excluir.svg";
import editarSVG from "@static/imgs/components/table/excluir.svg";
import showSVG from "@static/imgs/components/table/eye.svg";
import lupaPNG from "@/assets/img/lupa.png";

export default {
  name: "Classe",
  components: {
    showSVG,
    excluirSVG,
    editarSVG,
    lupaPNG,
  },
  methods: {
    addPage() {
      this.$router.push("/classe/addClasse");
    },
    viewPage() {
      this.$router.push("/classe/viewClasse");
    },
    editPage() {
      this.$router.push("/classe/editClasse");
    },
  },
};
</script>

<style>
hr {
  position: relative;
  right: 18px;
}

br {
  content: "";
  margin: 0.6em;
  display: block;
}

.title-classe {
  font-weight: 900;
  font-size: 2.5em;
  color: #cf0209;
  font-family: "Segoe_UI_Black_2";
  letter-spacing: 1px;
}

.title-search-classe {
  font-size: 1em;
}

.form-inputStyleF {
  height: 34px;
  color: black;
  background: #e6e6e6;
  border: 1px #e6e6e6 solid;
  border-radius: 5px;
}

.titleThF {
  font-family: "Segoe_UI";
  text-align: left;
  margin-left: 30px;
  font-size: 1.4em;
  font-weight: normal;
  color: #5b6873;
}

.MtitleThF {
  width: 20px;
  font-family: "Segoe_UI";
  font-size: 0.9em;
  font-weight: normal;
  color: #5b6873;
}

.contentTdF {
  font-family: "Segoe_UI";
  text-align: left;
  margin-left: 30px;
  color: #cf0209;
  font-size: 1.4em;
  font-weight: normal;
}

.btnIncluir {
  background: #cf0209 0% 0% no-repeat padding-box;
  opacity: 1;
  color: #fff;
  width: 180px;
  height: 40px;
  font-weight: bold;
  border-radius: 5px;
  border-color: #cf0209;
}

.del-alert {
  color: #cf0209;
  font-size: 1.2em;
  font-family: "Segoe_UI_Black_2";
}

.btn-del{
  margin-right: 10px;
  background: #c2c3c434 ;
  border-radius:17px;
  padding: 8px 30px 8px 30px;
  color: #5B6873;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 500px;
  height: 190px !important;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid white;
  border-radius: 0;
  outline: 0;
}

.content-modelGeneral {
  margin-bottom: 80px;
}

.icon-trash {
  cursor: pointer;
   display: inline;
  width: 32px;
  height: 24px;
}

.icon-edit {
  cursor: pointer;
  display: inline;
  width: 15px;
  height: 15px;
}

.content-iconSearch-classe {
  width: 23px;
  height: 23px;
  background: transparent url("/src/assets/img/lupa.png") 0% 0% no-repeat
    padding-box;
  opacity: 1;
}

@font-face {
  font-family: "Segoe_UI_Bold";
  src: local("Segoe_UI_Bold"),
    url(~@/assets/font/Segoe_UI_Bold.ttf) format("truetype");
}

@font-face {
  font-family: "Segoe_UI_Black_2";
  src: local("Segoe_UI_Bold"),
    url(~@/assets/font/Segoe_UI_Black_2.ttf) format("truetype");
}

/*  Mobile  */

@media (max-width: 992px) {
  .title-classe {
    font-size: 1.7em;
  }


  .btnIncluir {
    width: 90px;
    height: 35px;
    font-size: 1em;
  }

  .titleThF {
    font-size: 0.8em;
  }

  .MtitleThF {
    text-align: center !important;
    font-size: 0.50em;
  }

  .contentTdF {
    font-size: 0.70em;
  }

 .icon-trash {
  width: 22px;
  height: 14px;
}

.icon-edit {
  width: 10px;
  height: 10px;
}

.content-iconSearch-classe {
  width: 13px;
  height: 13px;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100% !important;
}

.content-modelGeneral {
  margin-bottom: 80px;

}

.model-content1 {
  padding-left: 10%;
  padding-right: 10%;
  padding-bottom: 25px;
  font-size: 0.9em;
}

.btn-del{
  margin-right: 10px;
  background: #c2c3c434 ;
  border-radius:17px;
  padding: 5px 25px 5px 25px;
  color: #5B6873;
  font-size: 0.9em;
}
.modal.show .modal-dialog {
  position: relative;
  top: 20%;
}

.form-inputStyleF {
  height: 24px;
}


}
</style>